<template>
  <div class="relative mx-auto mb-16 flex w-full max-w-screen-sm flex-col px-6">
    <portal to="topbar-left">
      <router-link :to="{ name: 'info-index' }" class="relative font-bold" key="info-articles-back" :class="{
        'text-white': isCheckedIn,
        'text-brand-dark': !isCheckedIn,
      }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 22" class="h-6 w-6">
          <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
            <path
              d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z" />
          </g>
        </svg>
      </router-link>
    </portal>

    <div class="mb-4 flex items-center justify-between">
      <h2 class="text-3xl font-bold">{{ $t("pages.articles") }}</h2>

      <div v-if="isAuthenticated"
        class="flex items-center rounded-full bg-brand-navblue px-4 py-2 text-white transition-all"
        :class="{ 'opacity-75': !hasFilter, 'opacity-100': hasFilter }" @click="onFilterClicked">
        <icon-bookmark class="mr-2 h-4 w-4" />
        <p>{{ $t("pages.favorited") }} ({{ favoritesCount }})</p>
      </div>
    </div>

    <form @submit.prevent="searchArticle" class="mb-2 space-y-2">
      <div class="flex flex-row space-x-2">
        <t-input-group class="w-full flex-1">
          <t-input class="dark:" :placeholder="$t('pages.search_placeholder')" type="text" v-model="searchText"
            @keydown="searchArticles" />
        </t-input-group>
      </div>
    </form>

    <div v-if="loading" class="mt-8 flex flex-col items-center space-y-4 text-center">
      <icon-loading class="h-4 w-4 animate-spin text-brand-dark" />
      <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
    </div>

    <template v-else>
      <div class="mb-4" v-for="category in categories" :key="category">
        <div v-if="Object.keys(articles).length">
          <h3 class="mb-4 text-xl font-bold">{{ category }}</h3>

          <div class="flex overflow-x-auto">
            <article-item v-for="article in articles[category]" @articleBookmarked="onBookmarkClicked"
              class="mr-4 w-64 flex-shrink-0 last:mr-0 lg:w-96" :article="article" :key="article.id" />
          </div>
        </div>

        <div v-else>
          <p>Geen gevonden</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconBookmark from "assets/icons/bookmark.svg";
import ArticleItem from "components/Articles/ArticleItem";
import { debounce as _debounce } from "lodash";

export default {
  name: "article-overview",

  components: {
    ArticleItem,
    IconBookmark,
  },

  data() {
    return {
      loading: false,
      favoritesCount: 0,
      hasFilter: false,
      isSearching: false,
      searchText: "",
    };
  },

  async mounted() {
    this.loading = true;
    await this.initArticles();
    this.countFavorites();
    this.loading = false;
  },

  methods: {
    async initArticles() {
      await this.$store.dispatch("getArticles", { searchText: this.searchText, language: this.$i18n.locale });
    },

    async onBookmarkClicked(bookmarked) {
      await this.$store.dispatch("getArticles", { searchText: this.searchText, language: this.$i18n.locale });

      if (bookmarked) this.favoritesCount++;
      else this.favoritesCount--;
    },

    onFilterClicked() {
      this.hasFilter = !this.hasFilter;
      if (this.hasFilter) {
        this.$store.dispatch("getFavorites");
      } else {
        this.$store.dispatch("getArticles", { language: this.$i18n.locale });
      }
    },

    countFavorites() {
      Object.values(this.articles).forEach((value) => value.forEach((v) => v.bookmarked && this.favoritesCount++));
    },

    searchArticles: _debounce(async function () {
      this.isSearching = true;
      await this.initArticles();
      this.isSearching = false;
    }, 500),
  },

  computed: {
    ...mapGetters(["articles", "categories", "isCheckedIn", "isAuthenticated"]),
  },
};
</script>
<!-- 
<style scoped>
.articles > section {
  @apply flex h-48 flex-row justify-center space-x-2 overflow-hidden overflow-x-auto xs:h-64;
}
.articles .box {
  @apply relative mr-3 block h-48 w-48 flex-shrink-0 overflow-hidden rounded-lg shadow-lg xs:h-64 xs:w-64;
}
.articles .box > .box-image {
  @apply relative z-10 overflow-hidden;
}
.articles .box > .box-image:before {
  @apply absolute inset-0 z-30 bg-gradient-to-t from-gray-700 via-brand-dark to-transparent;
}
.articles .box > .box-description {
  @apply absolute z-20;
}
.articles .box.has-image > .box-description {
  @apply absolute inset-0 text-left xs:inset-auto xs:inset-x-0 xs:bottom-0 xs:h-28;
  /* @apply shadow-articlepop; */
}
.articles .box.no-image > .box-description {
  @apply absolute inset-0;
  @apply bg-gradient-to-b from-gray-700 via-gray-800 to-black pt-16;
}
</style> -->
