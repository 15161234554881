<template>
  <div class="relative flex flex-col justify-end p-4 mb-4 overflow-hidden rounded-lg h-80 article__item">
    <button
      type="button"
      @click="updateBookmark"
      class="absolute top-0 right-0 flex items-center justify-center p-4 text-white bg-opacity-75 rounded-bl z-75 focus:outline-none focus:ring-2 focus:bg-black ring-white"
    >
      <icon-bookmark v-if="bookmarked" class="w-4 h-4" />
      <icon-bookmark-outline v-else class="w-4 h-4" />
    </button>
    <h4 class="relative z-20 text-lg font-bold text-white">
      {{ article.title }}
    </h4>
    <p class="relative z-20 my-4 text-white">{{ article.excerpt }}..</p>
    <router-link class="z-20 font-bold text-brand-navblue" :to="{ name: 'article-single', params: { id: article.id } }">
      <span class="absolute inset-0 z-10"></span>
      {{ $t("read_more") }}
    </router-link>

    <div class="absolute top-0 right-0 w-full h-full article__gradient z-5"></div>
    <img
      class="absolute top-0 right-0 z-0 object-cover w-full h-full"
      :src="
        article.image
        ? article.image
        : require('assets/articles/placeholder.jpg')
      "
      alt="Article image"
    />
  </div>
</template>

<script>
import IconBookmark from "assets/icons/bookmark.svg";
import IconBookmarkOutline from "assets/icons/bookmark-outline.svg";
import RepositoryFactory from "repositories/RepositoryFactory";

const articleRepository = RepositoryFactory.get("articles");

export default {
  name: "ArticleItem",

  components: {
    IconBookmark,
    IconBookmarkOutline,
  },

  props: {
    article: {
      type: Object,
    },
  },

  data() {
    return {
      bookmarked: this.article.bookmarked,
    };
  },

  methods: {
    async updateBookmark() {
      const copyBookmarked = this.bookmarked;
      this.bookmarked = !this.bookmarked;
      const { data } = await articleRepository.bookmarkArticle(this.article.id, !this.article.bookmarked);

      if (data.success) {
        this.$emit("articleBookmarked", !this.article.bookmarked);
      } else {
        this.bookmarked = copyBookmarked;
      }
    },
  },
};
</script>

<style scoped>
.article__gradient {
  background-image: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.article__item {
  will-change: transform;
}
</style>
